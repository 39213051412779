import "./App.css";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { Container, Row, Col } from "react-bootstrap";
import { loadFull } from "tsparticles";
import { useEffect, useMemo, useState } from "react";
import {
  Github,
  Linkedin,
  FilePerson,
  EnvelopeFill,
  ChevronDown,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import AboutMe from "./AboutMe.jsx";
import ScrollPortfolio from "./ScrollPortfolio.jsx";
// import Travel from "./Travel.jsx"
import Switzerland from "./Switzerland.jsx"
import particleConfig from './config/options.json'
 
const facts = [
<>Expert <a target="_blank" rel="noopener noreferrer" className="text-decoration-none" href="https://www.last.fm/user/chiefton117">Scrobbler 🎧</a></>,
"Serverless Computer in Seattle 💻", "ISO 8601 Supporter 🕒", "Vermont Maple Syrup Enjoyer 🍁", 
<><a target="_blank" rel="noopener noreferrer" className="text-decoration-none" href="https://nohello.net/en/">No Hello</a> Believer 🚫</>]


function Home() {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime.
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    // console.log(container);
  };

  const options = useMemo(
    () => (particleConfig),
    []
  );

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <div className="particles-js">
        {getParticleLander(init, particlesLoaded, options)}
      </div>
      <Container
        id="home"
        className="mx-auto justify-content-center d-flex align-items-center content align-self-center"
        fluid
      >
        <Row>
          <Col
            className="text-center text-light mx-auto align-items-center justify-content-center"
            xs={{ span: 6, offset: 3 }}
          >
            <h1 className="display-3 text-light">Tim Harrold</h1>
            <h4>{facts[Math.floor(Math.random()*facts.length)]}</h4>
            <hr
              style={{
                height: "1px",
                border: "1px solid white",
                color: "#fff",
              }}
            />
            <span>
              <Row>
                <Col xs={{ span: 2, offset: 2 }}>
                  <a
                    href="https://github.com/timjhh"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Github size={"75%"} title="Github" className="home-icon" />
                  </a>
                </Col>
                <Col xs={2}>
                  <Link to={{ pathname: "/Resume.pdf" }} target="_blank">
                    <FilePerson
                      size={"75%"}
                      title="Resume"
                      className="home-icon"
                    />
                  </Link>
                </Col>
                <Col xs={2}>
                  <a
                    href="https://www.linkedin.com/in/tim-harrold-02b249180/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Linkedin
                      size={"75%"}
                      title="LinkedIn"
                      className="home-icon"
                    />
                  </a>
                </Col>
                <Col xs={2}>
                  <a
                    href="mailto:timjharrold@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <EnvelopeFill
                      size={"75%"}
                      title="Email"
                      className="home-icon"
                    />
                  </a>
                </Col>
              </Row>
              <a href="#aboutme">
                <ChevronDown
                  title="See more"
                  className="mt-5"
                  style={{ color: "white" }}
                />
              </a>
            </span>
          </Col>
        </Row>
      </Container>
      <Container
        id="aboutme"
        className="d-flex justify-content-center align-items-center mt-5 content"
        fluid
      >
        <Col lg={3}></Col>
        <Col lg={6}>
          <AboutMe />
        </Col>
        <Col lg={3}></Col>
      </Container>
      <Container
        id="travel"
        className="d-flex justify-content-center align-items-center mt-5 content"
        fluid
      >
        <Col lg={3}></Col>
        <Col lg={6}>
          <Switzerland />
        </Col>
        <Col lg={3}></Col>
      </Container>
      
      <Container
        id="portfolio"
        className="d-flex justify-content-center mt-5 content"
        fluid
      >
        <Col lg={3}></Col>
        <Col lg={6}>
          <ScrollPortfolio />
        </Col>
        <Col lg={3}></Col>
      </Container>

    </div>
  );
}

function getParticleLander(init, particlesLoaded, options) {
  if (init) {
    return (
      <Particles
        className="w-100"
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />
    );
  }
  return <></>;
}

export default Home;

import { Container, Row, Col, Form } from 'react-bootstrap';
import * as d3 from 'd3';
import { useEffect, useState } from 'react';
import './App.css';
import { ChevronDown } from 'react-bootstrap-icons';


function Switzerland() {

    const countries = []

    const types = {
        "spherical": ["geoOrthographic"],
        "ortho": ["geoMercator", "geoEqualEarth", "geoStereographic"]
    }
    const mapFunctions = {
        "geoOrthographic": d3.geoOrthographic(),
        "geoMercator": d3.geoMercator(),
    }

    const [mapLoaded, setMapLoaded] = useState(false)
    const [continents, setContinents] = useState(4)
    const [mapType, setMapType] = useState("geoOrthographic")
    const [geoJson, setGeoJson] = useState(null)
    const [inter, setInter] = useState(null)

    const width = 900, height = 500;

    var graticule = d3.geoGraticule()
        .step([10, 10]);

    function getProjection() {
        return d3.geoMercator()
            .scale(200)
            .center([0, 200])
            .translate([width / 2, height / 2])
    }

    useEffect(() => {
        if (!mapLoaded) {
            fetch("./ch.geojson")
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    setGeoJson(data);
                    console.log(data)
                    let proj = getProjection()
                    let path = d3.geoPath().projection(proj)

                    // d3.select("#travelmap")
                    //     .append("g")
                    //     .attr("id", "grats")
                    //     .append("path")
                    //     .datum(graticule)
                    //     .attr("d", path)
                    //     .attr("id", "graticule")
                    //     .style("fill", "none")
                    //     .style("stroke", "#ccc");

                    d3
                        .select("#travelmap")
                        .attr("viewBox", [0, 0, width, height])
                        .attr("width", width)
                        .attr("height", height)
                        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
                        .append("g")
                        .attr("id", "pathsG")
                        .selectAll(".geos")
                        .data(data.features)
                        .join("path")
                        .attr("class", "geos")
                        .attr("id", "land")
                        .style("stroke-width", 0.1)
                        .style("stroke", "black")
                        .attr("d", path)
                        .style("fill", d => {
                            return "white"
                        })
                        .on('mouseover', function (d, i) {
                            d3.select(this).transition()
                                .duration(50)
                                .style('fill', d => countries.includes(i.properties.name) ? "rgba(70, 130, 180, 0.8)" : "rgba(211,211,211, 1)")
                        })
                        .on('mouseout', function (d, i) {
                            d3.select(this).transition()
                                .duration(50)
                                .style('fill', d => countries.includes(i.properties.name) ? "rgba(70, 130, 180, 1)" : "white")
                        })
                   // setSpin(data, proj)
                })
                .catch((err) => {
                    console.log("Error reading data " + err);
                });
        }

        // function setSpin(data, proj) {
        //     let intr = setInterval(() => {
        //         let projection = d3.geoPath().projection(proj.rotate([yaw, -45]))
        //         d3.select("#grats")
        //         .select("path")
        //         .attr("d", projection)
    
        //         d3.selectAll(".geos")
        //             .data(data ? data.features : geoJson.features)
        //             .join("path")
        //             .attr("d", projection)
        //         yaw -= 0.2
        //     }, 30)
        //     setInter(intr)
        //     console.log(d3.selectAll("path:not([id])").remove())
        //     d3.selectAll("path:not([id])").attr("d", "")
        //     //console.log(d3.selectAll("path:not([id])"))
        // }

    }, []);

    // useEffect(() => {
    //     clearInterval(inter)
    //     if (!mapLoaded) return;
    //     let proj = getProjection()

    //         d3.selectAll(".geos")
    //             .data(geoJson.features)
    //             .join("path")
    //             .attr("d", d3.geoPath().projection(proj))
    //             .attr("class", "geos")

    // }, [mapType])



    return (
        <>
            <Row>
                <Container>
                    <Row className='mb-2 w-100'>
                        <Col xs={4}>
                            <h1 className='display-3 mb-0'>Switzerland</h1>
                        </Col>
                        <Col xs={{ offset: 4, span: 4 }}>
                            <Form.Label className='d-flex flex-row-reverse'>Map Projection</Form.Label>
                            <Form.Group aria-label="map type selector">
                                <Form.Control
                                    as="select"
                                    value={mapType}
                                    onChange={e => {
                                        setMapType(e.target.value);
                                        clearInterval(inter)
                                    }}
                                >

                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
                <svg id="travelmap"></svg>
                <p className='text-center'>✈️ {countries.length} Countries | {continents} Continents | 🌎 Progress {(countries.length / 195).toFixed(1)}%</p>
            </Row>
            <Row>
                <a href="#portfolio"><ChevronDown title="Portfolio" className="d-block mx-auto mt-5" style={{ color: "black" }} /></a>
            </Row>
        </>
    );
}

export default Switzerland;
